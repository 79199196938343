import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { ButtonGroup, Button } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "buttongroup"
    }}>{`ButtonGroup`}</h1>
    <p>{`Add sensible margin for each button inside it.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<ButtonGroup>\n  <Button>First button</Button>\n  <Button>Second button</Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button mdxType="Button">First button</Button>
    <Button mdxType="Button">Second button</Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "segment-buttons"
    }}>{`Segment buttons`}</h2>
    <p>{`Group buttons together.`}</p>
    <Playground __position={1} __code={'<div>\n  <ButtonGroup segment mb=\"sm\">\n    <Button>First</Button>\n    <Button>Second</Button>\n    <Button variant=\"primary\">Third</Button>\n    <Button>Fourth</Button>\n  </ButtonGroup>\n  <ButtonGroup segment>\n    <Button shape=\"cool\">First</Button>\n    <Button shape=\"cool\">Second</Button>\n    <Button shape=\"cool\" variant=\"primary\">\n      Third\n    </Button>\n    <Button shape=\"cool\">Fourth</Button>\n  </ButtonGroup>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ButtonGroup segment mb="sm" mdxType="ButtonGroup">
      <Button mdxType="Button">First</Button>
      <Button mdxType="Button">Second</Button>
      <Button variant="primary" mdxType="Button">Third</Button>
      <Button mdxType="Button">Fourth</Button>
    </ButtonGroup>
    <ButtonGroup segment mdxType="ButtonGroup">
      <Button shape="cool" mdxType="Button">First</Button>
      <Button shape="cool" mdxType="Button">Second</Button>
      <Button shape="cool" variant="primary" mdxType="Button">
        Third
      </Button>
      <Button shape="cool" mdxType="Button">Fourth</Button>
    </ButtonGroup>
  </div>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <p>{`This component is extended from the `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` component.`}</p>
    <Props of={ButtonGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      